<template>
  <v-navigation-drawer v-model="drawer" absolute temporary>
    <v-list nav flat>
      <v-list-group no-action :value="false">
        <template v-slot:activator>
          <v-list-item-title>
            {{ $store.state.auth.user.firstname }}
            {{ $store.state.auth.user.lastname }}
          </v-list-item-title>
        </template>
        <v-list-item link @click="logout()">
          <v-list-item-title>Cerrar Sesión</v-list-item-title>
          <v-list-item-action>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item
          v-for="(item, i) in items"
          v-show="hasPermission(item.permission)"
          :key="i"
          :to="item.path"
        >
          <v-list-item-icon>
            <font-awesome-icon size="lg" :icon="item.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    permissions: [],
    selectedItem: 0,
    items: [
      {
        text: "Inicio",
        icon: "home",
        path: { name: "Home" },
        permission: "any",
      },
      {
        text: "DTEs",
        icon: "file-invoice",
        path: { name: "HomeDtes" },
        permission: "_view_dte",
      },
      {
        text: "Reportes",
        icon: "file-excel",
        path: { name: "HomeReports" },
        permission: "_view_reports",
      },
      {
        text: "Cuentas",
        icon: "money-check-alt",
        path: { name: "accounts-movement-all" },
        permission: "_view_accounts_movement",
      },
      {
        text: "Contabilidad",
        icon: "calculator",
        path: { name: "accounting-register-buy-home" },
        permission: "_view_accountings_register_buy",
      },
      {
        text: "Compras",
        icon: "cart-arrow-down",
        path: { name: "voucher-home" },
        permission: "_view_sale_orders",
      },
      {
        text: "Flota",
        icon: "truck-moving",
        path: { name: "fleet-home" },
        permission: "_view_fleets",
      },
      {
        text: "Proyectos",
        icon: "building",
        path: { name: "project-list-all" },
        permission: "_view_project",
      },
      {
        text: "Intersucursal",
        icon: "money-check",
        path: { name: "dealing-list-all" },
        permission: "_view_internalaccount",
      },
    ],
  }),
  computed: {
    drawer: {
      get() {
        return this.$store.getters.drawerState;
      },
      set(v) {
        return this.$store.commit("toggleDrawerState", v);
      },
    },
  },
  mounted() {
    this.permissions = this.$store.state.auth.user.permissions;
  },
  methods: {
    hasPermission(permission) {
      return this.permissions.includes(permission) || permission === "any" ? true : false;
    },
    logout() {
      this.$store.dispatch("auth/logout").then(
        () => {
          this.$router.push({ path: "/auth/login" });
          this.drawer = false;
          this.$forceUpdate();
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
